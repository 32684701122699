import React from "react";
import BackgroundImage from "gatsby-background-image";
import { Link, graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import {
  IoMdArrowDropup,
  IoMdArrowDropdown,
  IoIosMegaphone,
} from "react-icons/io";

import { Container, Wrapper, Box } from "../components/util";
import { AuthorCard, Breadcrumb } from "../components/site";
import Image from "../components/image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const MediaRelease = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;
  border-radius: ${(props) => props.theme.styles.borderRadius};
  background-color: ${(props) => props.theme.colours.midGrey};
  margin: 30px 0;

  svg {
    font-size: 35px;
    margin-right: 25px;
    color: ${(props) => props.theme.colours.midDark};
  }

  p {
    width: calc(100% - 50px);
    margin-bottom: 0 !important;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    text-align: center;
    padding: 20px;

    svg {
      margin-right: 0;
    }

    p {
      width: 100%;
      margin-top: 20px;
    }
  }
`;

const StatsWrapper = styled(Wrapper)`
  span {
    &.increase {
      color: ${(props) => props.theme.colours.green};
      font-weight: 500;
    }
    &.decrease {
      color: ${(props) => props.theme.colours.red};
      font-weight: 500;
    }
    svg {
      font-size: 30px;
      position: relative;
      top: 9px;
      margin-right: -5px;
    }
  }
`;

const RobQuote = styled(Box)`
  padding: 30px 40px;
  border-radius: ${(props) => props.theme.styles.borderRadius};
  background-color: ${(props) => props.theme.colours.midGrey};
`;

const PayrollStats = (props) => (
  <Layout>
    <Seo
      title="Payroll Stats | Employee & Payroll Statistics NZ | FlexiTime"
      description="The latest payroll, employee & COVID-19 statistics from NZ businesses using FlexiTime."
      pathname={props.location.pathname}
    />
    <Breadcrumb
      parentPage="Media"
      parentPageLink="/media"
      currentPage="FlexiTime NZ Payroll Statistics"
    />
    <Container>
      <StaticQuery
        query={graphql`
          query {
            desktop: file(relativePath: { eq: "home/PayrollStatsHeader.jpg" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={(data) => {
          const imageData = data.desktop.childImageSharp.fluid;
          return (
            <BackgroundImage
              Tag="section"
              fluid={imageData}
              backgroundColor={`#00adef`}
            >
              <Wrapper className="-textCenter">
                <div>
                  <h1 css={{ color: "#fff" }}>
                    FlexiTime NZ Payroll Statistics
                  </h1>
                </div>
              </Wrapper>
            </BackgroundImage>
          );
        }}
      />
    </Container>
    <Container>
      <StatsWrapper className="font-bump" maxWidth={800}>
        <h4>
          Payroll statistics provide a unique insight into the current
          employment landscape in countries around the world. From time to time
          FlexiTime will release aggregated data from our customer base that
          shows trends and changes happening in small business workplaces across
          New Zealand.
        </h4>
        <hr css={{ marginTop: "100px!important" }} />
        <div>
          <h2>COVID-19 Payroll Statistics</h2>
          <p>
            The impact of the COVID-19 crisis on workplaces has been
            significant. From 26 March to 28 April 2020 the majority of New
            Zealand businesses were shut down completely during Alert Level 4.
          </p>
          <p>
            As we move between the alert levels and gradual easing of
            restrictions on workplaces we’re providing regular payroll
            statistics to help track the real impact of COVID-19 on New Zealand
            businesses and employees.
          </p>
          <p>
            Our analysis is focused on a sample of more than 23,000 employees
            paid weekly, with these regular workers likely to see the biggest
            impact from COVID-19.
          </p>
          <p>
            <b>
              We’re tracking the following statistics for weekly employees only:
            </b>
          </p>
          <ul>
            <li>
              <b>Average weekly gross payroll</b>
              <br />
              The total weekly cost of payroll to the business, before tax and
              other deductions.
            </li>
            <li>
              <b>Average weekly earnings</b>
              <br />
              An employee’s total weekly earnings, before tax and other
              deductions.
            </li>
          </ul>
          <p>
            Weekly payroll statistics typically reflect wage data from the
            previous week. So the impact of moving to Alert Level 4 at 11.59pm
            on March 25th was seen in payroll figures for the week ending April
            4th.
          </p>
          <p>
            FlexiTime specialises in payroll for companies with part time and
            casual employees, including hospitality and tourism - sectors hit
            particularly hard by the pandemic.
          </p>
          <p>
            For any questions about these statistics, please contact Jake
            Harvey, CMO -{" "}
            <a href="mailto:jake@flexitime.works">jake@flexitime.works</a>.
          </p>
        </div>
        <Box stackGap={120}>
          <div>
            <div className="badge -green" css={{ marginBottom: "30px" }}>
              Latest
            </div>
            <h4>
              <b>Week ending June 20th</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 20 June 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$9,803</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 2.4%
                </span>{" "}
                from previous week)
                <Image
                  filename="PayrollStats_AveWeeklyGrossPayroll.png"
                  alt="FlexiTime NZ COVID-19 Payroll Stats | Average Weekly Gross Payroll"
                />
              </li>
              <li>
                Average weekly earnings: <b>$893.83</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 3.0%
                </span>{" "}
                from previous week)
                <Image
                  filename="PayrollStats_AveWeeklyEarnings.png"
                  alt="FlexiTime NZ COVID-19 Payroll Stats | Average Weekly Earnings"
                />
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "This week's figures cover the move to Alert Level 1 at
                  midnight on June 8th. It's great to see that average weekly
                  earnings are pretty much back where they started, at 98.7% of
                  our pre-COVID baseline. Average weekly gross payroll is at
                  98.2% of pre-COVID levels, indicating that companies in our
                  sample are paying nearly as many employees now as they were in
                  mid-March."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending June 13th</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 13 June 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$9,572</b> (
                <span className="decrease">
                  <IoMdArrowDropdown /> 1.8%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$868.09</b> (
                <span className="decrease">
                  <IoMdArrowDropdown /> 0.7%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "Not much to report this week, with a very small decrease in
                  both the figures we're tracking."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending June 6th</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 6 June 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$9,750</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 1.4%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$874.02</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 0.2%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "While we moved to Alert Level 1 on Monday night, this week's
                  stats still reflect earnings during Level 2. Very little
                  change to report with average weekly earnings and gross
                  payroll costs stabilising after a couple of weeks of steady
                  increases."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending May 30th</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 30 May 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$9,611</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 3.5%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$872.42</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 4.0%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "More positive news as average weekly earnings increased by
                  4%, bringing them to 96.3% of pre-COVID levels."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending May 23rd</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 23 May 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$9,274</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 3.9%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$837.24</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 3.5%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "Average weekly gross payroll and average weekly earnings are
                  both up again, with this week's figures reflecting the move to
                  Alert Level 2. Average weekly earnings are now at 92% of
                  pre-COVID levels."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending May 16th</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 16 May 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$8,930</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 0.5%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$808.70</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 1.4%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "We moved to Alert Level 2 on Thursday May 14th, but this
                  week's payroll figures reflect hours worked by employees
                  during the previous week - while the country was still at
                  Alert Level 3. The impact of the move to Alert Level 2 on next
                  week's figures will be interesting."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending May 9th</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <MediaRelease>
              <IoIosMegaphone />
              <p>
                <b>Media Release:</b>
                <br />
                12 May -{" "}
                <Link to="/media/flexitime-reports-average-weekly-earnings-rise-significantly-for-employees-in-level-3">
                  FlexiTime reports average weekly earnings rise significantly
                  for employees in Level 3
                </Link>
              </p>
            </MediaRelease>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 9 May 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$8,883</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 7.2%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$797.60</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 7.7%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "It's great to see a significant increase in average weekly
                  earnings as a result of moving to Alert Level 3. We saw some
                  industries, like trades, go from full lockdown to being able
                  to work almost as normal. Other industries like hospitality
                  and retail were able to get some employees back to work in a
                  more limited way, by offering click and collect or delivery
                  services. Hopefully this is a trend that we'll see continue in
                  the coming weeks."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending May 2nd</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 2 May 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$8,287</b> (
                <span className="decrease">
                  <IoMdArrowDropdown /> 0.7%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$740.25</b> (
                <span className="decrease">
                  <IoMdArrowDropdown /> 0.5%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "Very little change this week. We moved back to Alert Level 3
                  on Tuesday, meaning a lot more businesses are able to operate
                  more freely. Hours worked since the move to Alert Level 3 will
                  be paid next week and any impact from the easing of
                  restrictions should be seen in next week’s figures."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending April 25th</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 25 April 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$8,345</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 2.9%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$744.06</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 1.2%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "We’ve seen a slight increase in both average weekly gross
                  payroll and average weekly earnings. This indicates that a
                  number of employees who have not been paid for several weeks
                  are back at work and essential services employees are working
                  a few more hours a week on average."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending April 18th</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 18 April 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$8,113</b> (
                <span className="decrease">
                  <IoMdArrowDropdown /> 1.3%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$735.51</b> (
                <span className="decrease">
                  <IoMdArrowDropdown /> 2.5%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "It appears things have stabilised a bit this week after the
                  upheaval of the past fortnight. We’ve seen a smaller decrease
                  in average weekly earnings this week, but in total employees
                  are now earning an average of 23.1% less than they were in
                  mid-March."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending April 11th</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <MediaRelease>
              <IoIosMegaphone />
              <p>
                <b>Media Release:</b>
                <br />
                15 April -{" "}
                <Link to="/media/flexitime-payroll-data-reveals-drop-in-wages-across-nz-due-to-covid-19">
                  FlexiTime payroll data reveals drop in wages across NZ due to
                  COVID-19
                </Link>
              </p>
            </MediaRelease>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 11 April 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$8,220</b> (
                <span className="decrease">
                  <IoMdArrowDropdown /> 2.9%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$754.36</b> (
                <span className="decrease">
                  <IoMdArrowDropdown /> 4.0%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "This week the requirement for wage subsidy recipients to pay
                  employees at least 80% of their usual rate was changed to a
                  recommendation. Some businesses that are unable to top up the
                  wage subsidy while receiving no revenue are now paying
                  employees the wage subsidy only, leading to a further 4% drop
                  in average weekly earnings per employee."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending April 4th</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 4 April 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$8,465</b> (
                <span className="decrease">
                  <IoMdArrowDropdown /> 14.6%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$785.81</b> (
                <span className="decrease">
                  <IoMdArrowDropdown /> 12.7%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "As expected the move to Alert Level 4 and subsequent closure
                  of many businesses has led to a sharp drop in average earnings
                  per employee and the total cost of payroll for businesses. It
                  is clear from the volume of support queries we’re receiving
                  that many businesses have applied for the wage subsidy and are
                  paying their employees a reduced rate while their business is
                  closed."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending March 28th</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 28 March 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$9,917</b> (
                <span className="decrease">
                  <IoMdArrowDropdown /> 1.1%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$900.27</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 0.1%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "New Zealand moved to Alert Level 3 and then on to Alert Level
                  4 this week, which will have a major impact on businesses and
                  employees. These numbers are based on hours worked last week
                  before the lockdown began, so we expect to see a significant
                  drop in next week’s figures."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending March March 21st</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 21 March 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$10,024</b> (
                <span className="increase">
                  <IoMdArrowDropup /> 0.4%
                </span>{" "}
                from previous week)
              </li>
              <li>
                Average weekly earnings: <b>$899.33</b> (
                <span className="decrease">
                  <IoMdArrowDropdown /> 0.7%
                </span>{" "}
                from previous week)
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "Very little change this week. The government has announced a{" "}
                  <a
                    href="https://www.payhero.co.nz/blog/covid-19-payroll-information"
                    target="_blank"
                    rel="noreferrer"
                  >
                    wage subsidy
                  </a>{" "}
                  to support New Zealanders and protect their jobs from the
                  impact of COVID-19."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
          <div>
            <h4>
              <b>Week ending March 14th</b>
              <br />
              COVID-19 and the latest wage trends
            </h4>
            <p>
              This is what FlexiTime’s weekly payroll data looked like in the
              seven days to 14 March 2020.
            </p>
            <ul>
              <li>
                Average weekly gross payroll: <b>$9,986</b>
              </li>
              <li>
                Average weekly earnings: <b>$905.50</b>
              </li>
            </ul>
            <RobQuote stackGap={25}>
              <p>
                <i>
                  "These are our baseline numbers for understanding the impact
                  of COVID-19. While there’s always a bit of variation from week
                  to week due to employees that work irregular hours, the
                  figures are pretty consistent for the week ending January 11th
                  to the week ending March 14th."
                </i>
              </p>
              <AuthorCard author="Rob" />
            </RobQuote>
          </div>
        </Box>
      </StatsWrapper>
    </Container>
  </Layout>
);

export default PayrollStats;
